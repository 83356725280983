import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-toggle"
export default class extends Controller {
  static targets = ["content"]
  connect() {
    this.outsideClickHandler = (event) => {
      if (!this.element.contains(event.target)) {
        this.contentTarget.classList.add("hidden")
        document.removeEventListener("click", this.outsideClickHandler)
      }
    }
  }

  toggle() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.contentTarget.classList.remove("hidden")
      document.addEventListener("click", this.outsideClickHandler)
    } else {
      this.contentTarget.classList.add("hidden")
      document.removeEventListener("click", this.outsideClickHandler)
    }
  }

  disconnect() {
    document.removeEventListener("click", this.outsideClickHandler)
  }
}
