// stimulus_helpers.js
export function setQueryParam(application, elem, queryKey, val) {
    if (val !== undefined) {
        elem.value = null;
        if (elem.dataset.controller !== undefined) {
            const itemController = application.getControllerForElementAndIdentifier(
                elem,
                elem.dataset.controller
            );
            itemController.teardown();

            itemController.argsValue = {
                ...itemController.argsValue, // Preserve existing keys and values
                [queryKey]: val, // Add or update the new key-value pair
            };
            itemController.setup();
        } else {
            console.log("No controller defined for child element");
        }
    }
}
