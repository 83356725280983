import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refresh-materialize"
export default class extends Controller {
  connect() {

    window.materializeForm.init();
    $('select').material_select();

    Materialize.updateTextFields();
  }
}
