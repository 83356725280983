import { Controller } from "@hotwired/stimulus";
import { setQueryParam } from "./stimulus_helper";

export default class extends Controller {
    static targets = ["employee_referral", "referrer_id"];

    connect() {
        $(this.employee_referralTarget).on("change", () => {
            setQueryParam(
                this.application, this.referrer_idTarget, "employee_referral",
                $(this.employee_referralTarget).val()
            );
        });
    }
}
