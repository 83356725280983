import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="partial-content-loader"
export default class extends Controller {

    static values = {
        url: String,
        paramName: String
    }

    static targets = ["source"]

    connect() {
        this.initializeSourceSelect()
    }

    initializeSourceSelect() {
        const sourceSelect = $(this.sourceTarget)

        const selectedValue = sourceSelect.val();
        sourceSelect.on('change', (event) => this.handleChange(event))
        if (selectedValue) {
            //this.updateContent(selectedValue)
        }
    }

    handleChange(event) {
        const selectedValue = event.target.value
        this.updateContent(selectedValue)
    }


    updateContent(selectedValue) {

        let url = this.urlValue;

        var urlQueryParams = new URLSearchParams();
        urlQueryParams.append(this.paramNameValue, selectedValue);

        if(urlQueryParams.toString() !== ''){
            url = `${url}?${urlQueryParams.toString()}`
        }


        fetch(url, {
            headers: {
                "Accept": "text/vnd.turbo-stream.html"
            }
        })
            .then(response => response.text())
            .then(html => {
                Turbo.renderStreamMessage(html)
            })

    }

}
